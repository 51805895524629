// older global "keyboard disabling" is removed from form.scss and links.sccs

// FOCUS RULES:

:focus {
    outline: 2px dashed $nero !important;
    outline-offset: 2px;
}

:focus:not(:focus-visible) {
    outline: none !important; /* Remove outline for mouse clicks */
}

// whenever we use a mouse to click on any page, the body gets the class "mouse-navigation",
// we use this to avoid seeing the outline meant for keyboard navigation only
.mouse-navigation :focus,
.mouse-navigation :focus-visible {
    outline: none !important;
}
