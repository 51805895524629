.table-general {
    padding: 10px 0;

    table {
        background: $white;
        border-collapse: collapse;
        width: 100%;

        @include mq($mobile-max) {
            width: 768px;
        }
    }

    tr {
        @include mq($tablet-max) {
            border: 3px solid $nero;
        }
    }

    th,
    td {
        border: 3px solid $nero;
        text-align: center;
        text-transform: uppercase;
        word-break: break-word;
        min-width: 134px;

        @include mq($tablet-max) {
            border: 1px solid $nero;
        }
    }

    th {
        font-family: $heading-pro-treble-extra-bold;
        font-size: 12px;
        line-height: 14px;
        padding: 20px 6px;
        height: 124px;
    }

    td {
        font-family: $heading-pro-treble-regular;
        font-size: 13px;
        line-height: 15px;
        padding: 20px 6px;
        height: 110px;
    }

    &.table-long {
        table {
            @include mq($until-dt-min) {
                width: 1144px;
            }
        }
    }

    @include mq($until-dt-min) {
        width: 100%;
        overflow-x: scroll;
    }
}
