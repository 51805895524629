@import "../05-components/dropdown";

.main-footer {
    background-color: $white;
    font-style: initial;
    overflow-x: hidden;

    /* Footer Social Icons */
    .social-icons {
        display: grid;
        grid-row-gap: 24px;
        justify-content: center;
        align-items: center;
        background: linear-gradient(135deg, #ffaa30 0%, #fe7000 80.21%);
        padding: 20px 22px;

        @include mq($not-mobile) {
            grid-auto-flow: column;
            padding: 32px 0;
            gap: 48px;
        }

        ul {
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            grid-column-gap: 40px;
            grid-row-gap: 24px;

            @include mq($not-mobile) {
                grid-auto-flow: column;
                grid-template-columns: unset;
                max-width: none;
                gap: 40px;
            }
        }

        p {
            font-family: $heading-pro-treble-extra-bold;
            font-size: 13px;
            font-weight: 700;
            line-height: 130%;
            color: $white;
            margin: 0;
            text-align: center;

            @include mq($not-mobile) {
                font-size: 14px;
                text-align: left;
            }
        }

        a {
            display: flex;
            color: $white;
            font-size: 0;

            @include mq($desktop-xxl) {
                &:hover {
                    opacity: 0.6;
                }
            }

            &:active,
            &:focus {
                outline: 0;
                outline-style: none;
            }

            &::before {
                content: "";
                display: block;
                position: relative;
                width: 36px;
                height: 36px;
                background-repeat: no-repeat;
                background-size: contain;
            }

            &.icon-email {
                &::before {
                    background-image: url("../../img/svg/email.svg");
                }
            }

            &.icon-fb {
                &::before {
                    background-image: url("../../img/svg/facebook.svg");
                }
            }

            &.icon-ins {
                &::before {
                    background-image: url("../../img/svg/instagram.svg");
                }
            }

            &.icon-tiktok {
                &::before {
                    background-image: url("../../img/svg/tiktok.svg");
                }
            }

            &.icon-x {
                &::before {
                    background-image: url("../../img/svg/twitter.svg");
                }
            }

            &.icon-snapchat {
                &::before {
                    background-image: url("../../img/svg/snapchat.svg");
                }
            }

            // Icons used for recruitment site
            &.icon-linkedin {
                &::before {
                    background-image: url("../../img/svg/linkedin.svg");
                }
            }

            &.icon-youtube {
                &::before {
                    background-image: url("../../img/svg/youtube.svg");
                }
            }

            &.icon-glassdoor {
                &::before {
                    background-image: url("../../img/svg/glassdoor.svg");
                }
            }
        }
    }

    /* Footer Navigation */
    .footer-main-menu {
        position: relative;
        background-color: $wild-sand;

        .footer-main-menu-container {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            grid-row-gap: 24px;
            grid-column-gap: 16px;
            padding: 32px 16px;
            margin: 0 auto;

            @include mq($mobile-pt) {
                grid-row-gap: 24px;
            }

            @include mq($until-dt-min) {
                justify-content: flex-start;
                grid-row-gap: 32px;
            }

            @include mq($not-mobile-tablet) {
                max-width: 1440px;
                padding: 56px 40px;
            }
        }

        h2 {
            @include paragraph-small;

            line-height: 130%;
            color: $nero;
            font-family: $heading-pro-treble-extra-bold;
            text-transform: capitalize;
            margin-bottom: 24px;

            &::after {
                display: block;
                content: "";
                width: 15px;
                height: 1px;
                margin-top: 8px;
                background-color: $safety-orange;
            }
        }

        a {
            position: relative;
            font-size: 14px;
            line-height: 130%;
            color: $nero;
            text-transform: none;

            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }

        .service-col {
            @include mq($until-dt-min) {
                width: 100%;
            }

            ul {
                column-count: 2;

                @include mq($mobile-max) {
                    column-count: 2;
                }

                @include mq($until-dt-min) {
                    width: max-content;
                    column-count: 3;
                    gap: 16px;
                }

                @include mq($not-mobile-tablet) {
                    width: max-content;
                }

                @include mq($desktop-xxl) {
                    column-count: 2;
                    gap: 32px;
                }

                li {
                    width: 170px;
                    line-height: 1;
                    padding-bottom: 8px;
                }
            }
        }

        .about-col {
            ul {
                column-count: 2;
                width: 100%;
                gap: 32px;

                @include mq($until-dt-min) {
                    gap: 16px;
                }

                li {
                    width: 170px;
                    line-height: 1;
                    padding-bottom: 8px;
                }
            }
        }
    }

    /* Footer Partners and Payments */
    .footer-partners-and-payments {
        display: grid;
        grid-row-gap: 32px;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        margin: 0 auto;
        padding: 32px 16px;
        background: $white;

        @include mq($not-mobile) {
            grid-auto-flow: column;
            gap: 56px;
        }

        @include mq($not-mobile-tablet-pt) {
            padding: 32px 40px;
        }

        @include mq($desktop-xxl) {
            max-width: 1440px;
            gap: 170px;
        }
    }

    .partner-wrapper {
        ul {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 16px;
        }

        li {
            display: flex;
        }

        a {
            display: flex;
            align-items: center;
            font-size: 0;

            &:hover {
                opacity: 0.6;
            }

            &::before {
                content: "";
                display: block;
                position: relative;
                background-repeat: no-repeat;
                background-size: contain;
            }

            &.logo-fitcert {
                &::before {
                    background-image: url("../../img/svg/fitcert.svg");
                    width: 72px;
                    height: 36px;
                }
            }

            &.logo-fitcert-tuvnord {
                &::before {
                    background-image: url("../../img/svg/fitcert-tuvnord.svg");
                    width: 36px;
                    height: 36px;
                }
            }

            &.logo-matrix {
                &::before {
                    background-image: url("../../img/svg/matrix.svg");
                    width: 109px;
                    height: 21px;
                }
            }

            &.logo-technogym {
                &::before {
                    background-image: url("../../img/svg/technogym.svg");
                    width: 109px;
                    height: 24px;
                }
            }

            &.logo-nxtlevel {
                &::before {
                    background-image: url("../../img/svg/nxtlevel.svg");
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }

    .payment-wrapper {
        ul {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 16px;
        }

        li {
            display: flex;
            align-items: center;
        }

        i {
            &::before {
                content: "";
                display: block;
                position: relative;
                width: 36px;
                height: 36px;
                background-repeat: no-repeat;
                background-size: contain;
            }

            &.logo-bancontact {
                &::before {
                    background-image: url("../../img/svg/bancontact.svg");
                }
            }

            &.logo-cardbancair {
                &::before {
                    background-image: url("../../img/svg/cardbancair.svg");
                }
            }

            &.logo-ideal {
                &::before {
                    background-image: url("../../img/svg/ideal.svg");
                }
            }

            &.logo-mastercard {
                &::before {
                    background-image: url("../../img/svg/mastercard.svg");
                }
            }

            &.logo-paypal {
                &::before {
                    background-image: url("../../img/svg/paypal.svg");
                }
            }

            &.logo-sofort {
                &::before {
                    background-image: url("../../img/svg/sofort.svg");
                }
            }

            &.logo-visa {
                &::before {
                    background-image: url("../../img/svg/visa.svg");
                }
            }
        }
    }

    /* Footer Country Switch and Legal */
    .footer-bottom {
        background-color: $nero;

        .footer-bottom-wrapper {
            position: relative;
            margin: auto;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            padding: 24px 16px;
            color: $white;

            @include mq($not-mobile) {
                padding: 32px 40px;
            }

            @include mq($desktop-xxl) {
                max-width: 1440px;
                justify-content: space-between;
                flex-wrap: nowrap;
            }
        }

        .dropdown--type-1 {
            .dropdown-selected-value {
                min-width: 197px;
            }

            .dropdown-toggle {
                padding: 12px 0;

                &::after {
                    top: 12px;
                }
            }

            .dropdown-item__wrapper {
                display: flex;
                align-items: center;
                gap: 8px;

                &.selected {
                    .icon-checkmark {
                        &::before {
                            background-image: url("../../img/svg/checkmark-icon-orange.svg");
                        }
                    }
                }
            }

            .dropdown-menu {
                min-width: 266px;
                right: -25px;
            }

            .icon-checkmark {
                &::before {
                    content: "";
                    background-image: none;
                    display: block;
                    position: relative;
                    width: 20px;
                    height: 20px;
                    background-repeat: no-repeat;
                    background-size: contain;
                }
            }
        }

        .flag-icon {
            margin-right: 8px;
        }

        .locale {
            display: flex;
            align-items: center;
            border-bottom: 2px solid rgba($white, 0.5);
            width: 100%;
            padding-bottom: 24px;

            @include mq($desktop-xxl) {
                width: auto;
                height: 24px;
                border-bottom: none;
                border-right: 2px solid rgba($white, 0.5);
                padding-right: 24px;
                padding-bottom: 0;
                margin-right: 24px;
            }
        }

        .footer-copy {
            flex-grow: 1;

            ul {
                display: grid;
                grid-template-columns: repeat(2, minmax(0, 1fr));
                padding-top: 24px;
                gap: 8px;

                @include mq($not-mobile) {
                    grid-template-columns: repeat(3, minmax(0, 1fr));
                }

                @include mq($desktop-xxl) {
                    display: flex;
                    justify-content: space-between;
                    padding-top: 0;
                    gap: 0;
                }

                li {
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 130%;
                }
            }

            a {
                display: flex;
                align-items: center;
                font-size: 14px;
                font-weight: 500;
                line-height: 130%;
                color: $white;
                text-transform: none;

                &:hover {
                    text-decoration: underline;
                }

                @include mq($desktop-xxl) {
                    height: 24px;
                }
            }
        }

        &.footer-bottom-recruitment {
            .footer-copy {
                ul {
                    @include mq($desktop-xxl) {
                        justify-content: space-around;
                    }
                }
            }
        }
    }
}
