.experience-assets-promobanner {
    .promo-banner__paragraph {
        color: $white;
    }

    .promo-banner__title--second {
        -webkit-text-stroke: 0;
    }

    .promo-banner {
        font-style: initial;
        margin-bottom: 140px;
    }
}

.promo-banner {
    position: relative;
}

.promo-banner__desktop-img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.promo-banner__content {
    max-width: 1120px;
    padding: 107px 0 132px;
    margin: 0 auto;

    @include mq($tablet-max) {
        padding: 20px 15px 450px;
    }
}

.promo-banner__title,
.promo-banner__paragraph {
    color: $nero;
}

.promo-banner__paragraph {
    margin-bottom: 30px;
    margin-right: auto;
    width: 40%;

    @include mq($not-mobile) {
        margin-bottom: 35px;
    }

    @include mq($tablet-max) {
        width: 100%;
    }
}

.promo-banner__title {
    margin-bottom: 23px;
    @include h3;

    @include mq($tablet-max) {
        margin-top: 15px;
        margin-bottom: 24px;
        @include h4;
    }

    .promo-banner__title.normal {
        font-weight: $heading-pro-treble-regular;
    }

    .promo-banner__title.bold {
        font-family: $heading-pro-treble-heavy;
    }
}

.promo-banner__title--second {
    display: block;
    -webkit-text-stroke: 1px $nero;
}

.promo-banner__title--second-plus {
    &::before {
        content: "+";
        margin-right: -5px;
        color: transparent;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: $safety-orange;
    }
}

.promo-banner__btn {
    position: relative;
    padding: 15px 89px;
    z-index: 5;

    @include mq($tablet-max) {
        margin-top: 29px;
        padding: 13px 10px;
        width: 100%;
    }

    @include mq($not-mobile) {
        width: auto;
    }
}

.promo-banner--gradient {
    background: linear-gradient($acadia 0%, $mortar 100%);

    img {
        display: none;
    }
}

.promo-banner--desktop-right {
    text-align: right;

    .promo-banner__paragraph {
        margin: 0 0 0 auto;
    }
}

.promo-banner--desktop-left {
    text-align: left;

    .promo-banner__paragraph {
        margin: 0 auto 50px 0;
    }
}

.promo-banner--mobile-right {
    @include mq($tablet-max) {
        text-align: right;

        .promo-banner__paragraph {
            margin: 0 0 0 auto;
        }
    }
}

.promo-banner--mobile-left {
    @include mq($tablet-max) {
        text-align: left;

        .promo-banner__paragraph {
            margin: 0 auto 0 0;
        }
    }
}

.promo-banner__image {
    position: absolute;
    bottom: -40%;
    left: -40%;
    transform: translateX(-50%);
    z-index: 4;

    @include mq($tablet-max) {
        top: 39%;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
    }

    @include mq($modal-tablet-max) {
        top: 35%;
    }

    @include mq($not-mobile-tablet) {
        top: -87px;
        left: 50%;
        width: 50%;
        transform: scale(0.6);
    }

    @include mq($laptop-st) {
        transform: none;
    }

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
        margin: auto;

        @include mq($not-mobile-tablet) {
            position: absolute;
            object-position: right;
            top: -81px;
        }
    }

    svg {
        width: 100%;
        height: 100%;
    }

    .zLinesTwo {
        path {
            @include mq($until-dt-med) {
                transform: translate(-250px, 50px);
            }
        }
    }

    path {
        opacity: 0;
    }
}

.promo-banner--jobs {
    background: $acadia;
    margin-bottom: 0;
    min-height: 560px;

    .promo-banner__content {
        max-width: 1240px;
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;

        @include mq($not-mobile-tablet) {
            padding: 20px 15px;
        }

        @include mq($tablet-max) {
            padding: 50px 15px;
        }
    }

    .promo-banner__title {
        @include h4;

        margin-top: 0;
        margin-bottom: 24px;

        @include mq($not-mobile-tablet) {
            @include h3;

            margin-bottom: 24px;
            width: 50%;
        }

        .promo-banner__title--main {
            display: block;
            -webkit-text-stroke: 1px $white;
            color: transparent;
        }

        .promo-banner__title--opposite {
            -webkit-text-stroke: 1px $white;
            color: $white;
        }
    }

    .promo-banner__paragraph {
        color: $white;

        @include mq($tablet-max) {
            margin-bottom: 0;
        }
    }

    .promo-banner__image {
        pointer-events: none;

        #waveBigLine1_jobspromobanner,
        #waveBigLine2_jobspromobanner {
            stroke-width: 10;
        }

        svg {
            width: 632px;
            height: 674px;
        }

        &.animation-WavesBig {
            svg {
                path {
                    transform: scale(1.5);
                }
            }

            @include mq($mobile-max) {
                #waveBigLine1_jobspromobanner,
                #waveBigLine2_jobspromobanner {
                    stroke-width: 10;
                }

                svg {
                    path {
                        transform: translate(-40px, -60px) scale(1.7);
                    }
                }
            }
        }

        &.animation-CurveAnimation-Two {
            svg {
                path {
                    transform: translate(0, -150px);
                }
            }

            @include mq($mobile-max) {
                svg {
                    path {
                        transform: translate(60px, -40px) scale(0.8);
                    }
                }
            }
        }

        @include mq($mobile-pt) {
            bottom: auto;
        }

        @include mq($mobile-max) {
            width: 632px;
            height: 674px;
        }

        @include mq($tablet-max) {
            display: flex;
            justify-content: center;
            align-items: center;
            left: auto;
            bottom: auto;
            top: 30%;
            transform: scale(0.8);
        }

        @include mq($not-mobile-tablet) {
            left: auto;
            top: 0;
            right: 0;
            transform: none;
            bottom: -40%;
            display: flex;
            justify-content: center;
            height: 674px;
            width: 632px;

            img {
                top: 0;
                width: 100%;
                object-fit: contain;
                object-position: right;
                position: static;
                height: auto;
            }
        }

        @include mq($laptop-st) {
            right: 12%;
        }
    }

    .promo-banner__btn {
        padding: 14px;
        max-width: 100%;

        // overiding default style for $mobile-max
        @include mq($mobile-max) {
            width: 310px;
        }

        @include mq($tablet-max) {
            margin-top: 36px;
            width: 310px;
        }

        @include mq($not-mobile-tablet) {
            width: 320px;
        }
    }

    &.promo-banner--gradient {
        background: radial-gradient(40% 50% at 76% 50%, #494949 0%, rgb(43 43 43 / 0%) 100%);
        background-color: $acadia;

        @include mq($tablet-max) {
            background: linear-gradient(284.67deg, $acadia 52.29%, $mortar 99.87%);
        }
    }

    @include mq($tablet-max) {
        min-height: 690px;
        display: flex;
        justify-content: center;
    }

    @include mq($not-mobile-tablet) {
        display: flex;
        align-items: center;
    }
}

.promo-banner__image-position--right {
    @include mq($not-mobile-tablet) {
        left: 0;
    }
}

.promo-quiz {
    padding-bottom: 140px;
    overflow: hidden;
}

// FAQ page
.content-page .main-content {
    .faq-promo-banner {
        section {
            max-width: 100%;
            margin-bottom: 0;
        }

        @include mq($not-mobile) {
            padding-top: 60px;
        }
    }
}
