.back-to-top {
    bottom: 50px;
    color: $black;
    opacity: 0;
    position: fixed;
    right: 50px;
    transition: opacity 0.3s ease-in-out;
    visibility: hidden;
    z-index: 10;
    text-transform: none;

    &::first-letter {
        text-transform: uppercase;
    }

    &.show {
        @include mq($not-mobile) {
            opacity: 1;
            visibility: visible;
        }
    }

    &::after {
        background-color: $safety-orange;
        border-radius: 50%;
        box-shadow: 0 0 20px 0 rgb(0 0 0 / 20%);
        @include iconfont-styles;

        content: svg(arrow-right);
        color: $white;
        display: inline-block;
        height: 30px;
        line-height: 30px;
        margin-left: 10px;
        text-align: center;
        transform: rotate(-90deg);
        width: 30px;
    }
}
