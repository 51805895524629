// marquee.scss
// cant put the animation within a MQ because of IE11

@keyframes marquee-animation {
    0% {
        left: 0;
    }

    100% {
        left: -1060px;
    }
}

@keyframes marquee-animation-pagewide {
    0% {
        left: 0;
    }

    100% {
        left: -2080px;
    }
}

.experience-assets-promobanner {
    .page-wide-marquee-wrapper {
        font-style: initial;
        margin-top: 20px;
    }
}

.page-wide-marquee-wrapper {
    width: 100%;
    max-width: $max-width;
    margin: auto;
    overflow: hidden;
    margin-top: 30px;
}

.page-wide-marquee {
    background-color: $white;
    margin: 30px auto;
    white-space: nowrap;
    display: flex;
    width: 4179px; //specific for the text; twice the space it needs to cover (2089.18)
    &.animate {
        div {
            animation: marquee-animation-pagewide 14s linear infinite;
            animation-delay: 0s;
        }
    }

    div {
        width: 4179px; //specific for the text; twice the space it needs to cover (2089.18)
        position: relative;
        white-space: nowrap;
        left: 0;
        display: flex;
    }

    span {
        font-size: 20px;
        font-family: $heading-pro-treble-extra-bold;

        @include mq($not-mobile) {
            font-size: 30px;
            font-family: $heading-pro-treble-heavy;
        }
    }

    hr {
        width: 49px;
        border: none;
        border-top: 3px solid $spray;
        margin: auto 18px;

        @include mq($not-mobile) {
            width: 73px;
            border-top: 6px solid $spray;
        }
    }
}
