// overruling existing components that arent cleaned up yet:
.top-promo-banner,
.experience-layouts-usptopbar {
    display: none;
}

.sticky-header {
    height: auto;
    position: sticky;
    transition: height 0.3s linear;
    top: 0;
    z-index: 41;
    width: 100%;
}

.main-page-header {
    background-color: transparent;
    top: 0;
    transition: all 0.3s ease-in-out;
    width: 100%;
    z-index: 40;
    min-height: 86px;
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 10%);

    @include mq($not-mobile) {
        min-height: 96px;
    }

    @include mq($dt-min) {
        min-height: 117px;
    }

    //  mobile grey 40 + white 46 = 86
    //  tablet grey 40 + white 56 = 96  << should kick in below 1280 now!
    //  desktop grey 45 + white 72 = 117

    &.non-sticky {
        position: relative;
        border-bottom: 1px solid $wild-sand;

        @include mq($tablet-max) {
            border-bottom: 0;
            height: auto;
        }
    }

    // this 3rd part thing is made so crappy that all possible states are applied when the search field opens
    .seamly-search-placeholder input:focus-visible {
        outline: none !important;
    }

    .icon-clock-24-tt::before {
        background-image: url("../../img/svg/icon-clock-24-tt.svg");
    }

    .icon-kettlebell-tt::before {
        background-image: url("../../img/svg/icon-kettlebell-tt.svg");
    }

    .icon-app-tt::before {
        background-image: url("../../img/svg/icon-app-tt.svg");
    }

    .icon-hart-barbell-tt::before {
        background-image: url("../../img/svg/icon-hart-barbell-tt.svg");
    }

    .icon-club-tt::before {
        background-image: url("../../img/svg/icon-club-tt.svg");
    }

    .icon-clubs-tt::before {
        background-image: url("../../img/svg/icon-clubs-tt.svg");
    }

    .icon-star-discount-tt::before {
        background-image: url("../../img/svg/icon-star-discount-tt.svg");
    }

    .icon-marker-tt::before {
        background-image: url("../../img/svg/icon-marker-tt.svg");
    }

    .icon-barbell-tt::before {
        background-image: url("../../img/svg/icon-barbell-tt.svg");
    }

    .icon-group-tt::before {
        background-image: url("../../img/svg/icon-group-tt.svg");
    }
}

.header-max-col {
    width: 100%;
    max-width: 1440px;
    padding: 0 40px;

    @include mq($mobile-tablet-pt) {
        padding: 0 16px;
    }
}

// first row
.header-row-usp {
    background-color: $wild-sand;
    min-height: 40px;
    display: flex;
    justify-content: center;

    @include mq($dt-min) {
        min-height: 45px;
    }

    .header-max-col {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include mq($until-dt-min) {
            justify-content: center;
        }
    }
}

// swiper and promobanner styling:

.header-usp-swiper-container {
    position: relative;
    display: inline-block;
    max-width: 100%;
    margin: auto;

    .main-page-header:not(.main-campaign-header) & {
        @include mq($dt-min) {
            padding: 0;
            margin: 0;
            flex: 1;
        }
    }

    &:has(.header-usp-navigation div:not(.swiper-button-lock)) {
        padding: 0 30px;

        .swiper-wrapper {
            cursor: grab;
        }
    }

    .header-usp-navigation {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }

    .arrow-prev,
    .arrow-next {
        display: none;
        width: 12px;
        height: 12px;
        position: absolute;
        top: calc(50% - 6px);
        cursor: pointer;
        line-height: 0;

        &.swiper-button-lock {
            display: none;
        }

        &.swiper-button-disabled {
            cursor: auto;
            opacity: 0.2;
        }

        &::before {
            content: svg(chevron-left2);
            @include iconfont-styles;

            font-size: 12px;
            line-height: 12px;
        }
    }

    .arrow-prev {
        left: 0;
    }

    .arrow-next {
        right: 0;
        transform: scale(-1, 1);
    }
}

.header-usp-swiper {
    line-height: 0;
    flex: 1;
    overflow: hidden;
    max-width: 100%;
    padding: 3px;

    @include mq($dt-min) {
        justify-content: flex-start;
    }

    .swiper-wrapper {
        display: none;
        gap: 40px;
        width: auto;
        height: auto;
        z-index: 2;
        cursor: auto;
    }

    &.swiper-initialized {
        .swiper-wrapper {
            display: inline-flex;
            gap: 0;
            align-items: center;
        }

        + .header-usp-navigation {
            .arrow-prev:not(.swiper-button-lock),
            .arrow-next:not(.swiper-button-lock) {
                display: block;
            }
        }
    }

    .swiper-slide {
        width: auto;
    }

    img,
    .image-wrapper {
        max-width: 16px;
        max-height: 16px;
        line-height: 0;
    }

    .title-wrapper,
    .title-wrapper a {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        text-transform: none;
        width: auto;
        display: flex;
        gap: 11px;
        align-items: center;

        @include mq($mobile-max) {
            justify-content: center;
        }

        .icon {
            flex-shrink: 0;
        }

        .value {
            @include line-clamp;
        }
    }

    .title-wrapper a {
        text-decoration: underline;

        @include mq($hover) {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .icon {
        position: relative;
        display: block;
        width: 16px;
        height: 16px;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
}

.header-promo-banner-container {
    display: flex;
    gap: 8px;
    align-items: center;

    &::before {
        content: "";
        width: 16px;
        height: 16px;
        background-image: url("../../img/svg/icon-star-discount-tt.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        flex-shrink: 0;
    }

    .promo-banner-text {
        @include line-clamp;
    }

    .promo-banner-text,
    a {
        font-size: 14px;
        line-height: 130%;
        text-transform: none;
        cursor: default;

        b,
        strong {
            font-family: $heading-pro-treble-extra-bold;
        }
    }

    a {
        &.is-link {
            cursor: pointer;
            @include line-clamp;

            &:not(.link-promo-counter) {
                color: $purple-heart;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        &.nobreak-link {
            flex-shrink: 0;
        }
    }



    .promo-banner-counter,
    .promo-banner-counter-days {
        position: relative;
        line-height: 130%;
        font-size: 12px;
        display: flex;
        gap: 2px;
        color: $purple-heart;
        flex-shrink: 0;
    }

    a.link-promo-counter {
        font-family: $heading-pro-treble-extra-bold;
        position: relative;

        &.is-link {
            &::after {
                content: "";
                position: absolute;
                height: 1px;
                width: 100%;
                bottom: 2px;
                left: 0;
                background-color: $purple-heart;
            }

            &:hover {
                &::after {
                    content: none;
                }
            }
        }

        .dD {
            font-family: $heading-pro-double-regular;
        }
    }

    .promo-banner-counter-days {
        font-family: $heading-pro-double-regular;

        b,
        strong {
            font-family: $heading-pro-treble-extra-bold;
        }
    }
}

.service-menu {
    display: none;

    &.desktop {
        @include mq($dt-min) {
            display: flex;
            gap: 24px;
            height: 100%;
            align-items: center;
            margin-left: auto;
            padding-left: 24px;
            flex-shrink: 0;

            .link-my-basicfit,
            .link-customer-service {
                &.active {
                    span {
                        &::before {
                            bottom: -10px;
                        }
                    }
                }
            }
        }
    }

    &.touch {
        @include mq($until-dt-min) {
            display: flex;
            flex-direction: column;
            margin: 0 24px;
            height: 190px;

            .link-my-basicfit,
            .link-customer-service {
                padding: 12px 4px;

                &.active {
                    span {
                        &::before {
                            bottom: -10px;
                        }
                    }
                }
            }

            .select-wrap {
                margin-top: 46px;
                display: flex;
                align-items: center;
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    height: 1px;
                    background-color: $mortar;
                    z-index: -2;
                }

                &::after {
                    position: static;
                    right: auto;
                    top: auto;
                    margin: 0;
                }
            }

            .language-selector-touch {
                font-family: $heading-pro-treble-regular;
                font-size: 14px;
                margin: 0;
                padding: 4px;
                background-color: transparent;
                border: 0;
                width: auto;
                height: 48px;
            }
        }
    }
}

.link-my-basicfit,
.link-customer-service {
    text-transform: none;
    font-family: $heading-pro-double-regular;
    font-size: 14px;
    display: inline-flex;
    gap: 8px;
    align-items: center;

    @include mq($until-dt-min) {
        align-self: flex-start;
    }

    &::before {
        @include iconfont-styles;

        line-height: normal;
        background-color: white;
        width: 24px;
        height: 24px;
        border-radius: 12px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }

    @include mq($hover) {
        &:hover {
            &::before {
                color: $safety-orange;
            }

            span {
                text-decoration: underline;
            }
        }
    }

    &.active {
        span {
            position: relative;

            &::before {
                content: "";
                position: absolute;
                height: 2px;
                left: 0;
                bottom: 0;
                right: 0;
                background-color: $safety-orange;
            }
        }
    }

    span {
        display: inline-block;
        height: 100%;
        position: relative;
    }
}

.link-my-basicfit {
    &::before {
        content: svg(my-basicfit);
    }
}

.link-customer-service {
    &::before {
        content: svg(customer-service);
    }
}

.country-selector {
    display: flex;
    align-items: center;
    position: relative;

    @include mq($not-mobile-tablet) {
        white-space: nowrap;
    }

    &:hover,
    body:not(.mouse-navigation) &:focus-within {
        .country-link {
            font-family: $heading-pro-double-regular;
            font-size: 14px;
            cursor: pointer;

            &::after {
                content: svg(chevron-up);
                color: $safety-orange;
            }
        }

        .country-menu {
            display: block;
        }
    }
}

.country-link {
    font-family: $heading-pro-double-regular;
    font-size: 14px;
    cursor: pointer;

    &::after {
        @include iconfont-styles;

        content: svg(chevron-down);
        font-size: 14px;
        position: relative;
        display: inline-block;
        margin-left: 4px;
    }
}

.country-menu {
    display: none;
    position: absolute;
    top: 30px;
    right: -4px;
    font-size: 14px;
    background-color: $white;
    border: 1px solid $grey-main;
    z-index: 1;
    box-shadow: 2px 2px 8px 0 rgb(0 0 0 / 16%);
    padding: 8px;

    a {
        position: relative;
        display: block;
        padding: 8px;
        color: $black;
        font-family: $heading-pro-double-regular;
        font-size: 14px;
        line-height: 130%;
        text-transform: none;

        + a {
            border-top: 1px solid $wild-sand;
            padding-top: 8px;
        }

        &:hover {
            text-decoration: underline;
        }

        &.active {
            font-family: $heading-pro-treble-extra-bold;
            pointer-events: none;
        }
    }
}

// second row
.header-row-navigation {
    background-color: $white;
    min-height: 56px;
    display: flex;
    justify-content: center;

    @include mq($not-mobile) {
        min-height: 56px;
    }

    @include mq($dt-min) {
        min-height: 72px;
    }

    .header-max-col {
        display: flex;
        gap: 24px;
        justify-content: space-between;
        align-items: center;

        @include mq($dt-min) {
            gap: 40px;
        }

        @include mq($mobile-pt) {
            gap: 10px;
        }
    }
}

.logo-link {
    display: inline-block;
    line-height: 0;
}

.bf-header-logo {
    order: 0;
    width: 92px;
    display: inline-block;
    line-height: 0;

    @include mq($dt-min) {
        width: 100px;
    }
}

//  new navigation:
.header-navigation {
    order: 1;
    flex: 1;
    height: 100%;

    .main-header-menu-wrapper {
        display: none;

        @include mq($until-dt-min) {
            flex-direction: column;
            display: flex;
            display: none;
            position: fixed;
            top: 0;
            bottom: 0;
            width: 100%;
            max-width: 360px;
            background-color: $wild-sand;
            z-index: 2;
            opacity: 0;
            right: -360px;
            transition:
                opacity 0.3s ease-in-out,
                transform 0.3s ease-out;
            pointer-events: none;

            &.takeplace {
                // preposition for animation
                display: flex;
            }

            &.visible {
                opacity: 1;
                transform: translate3d(-360px, 0, 0);
                pointer-events: all;
            }
        }

        @include mq($mobile-pt) {
            max-width: 100%;
            right: -100%;

            &.visible {
                transform: translate3d(-100%, 0, 0);
            }
        }

        @include mq($dt-min) {
            display: flex;
            height: 100%;
        }
    }
}

.touch-menu-header {
    display: none;

    @include mq($until-dt-min) {
        display: flex;
        align-items: center;
        gap: 24px;
        padding: 12px 16px;
        height: 56px;
    }

    .touch-menu-logo {
        width: 92px;
        position: relative;
        display: block;
        margin-right: auto;
        line-height: 0;

        img {
            width: 100%;
            display: inline-block;
            line-height: 0;
        }
    }

    .btn {
        &.become-member {
            order: initial;
        }
    }

    .touch-menu-close {
        display: flex;
        font-size: 0;
        cursor: pointer;
        background-color: transparent;
        border: 0;

        &::after {
            @include iconfont-styles;

            content: svg(close-rounded);
            display: block;
            font-size: 16px;
            position: relative;
            margin: 0;
            color: $black;
        }
    }
}

.menu-popup-overlay {
    display: none;

    .has-menu-popup-overlay & {
        display: block;
        position: fixed;
        opacity: 0.25;
        background: $nero;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        cursor: pointer;
    }
}

.menu-body {
    height: 100%;
    width: 100%;

    @include mq($until-dt-min) {
        height: calc(100% - 56px);
        display: flex;
        flex-direction: column;
        overflow: auto;

        @include scrollbar;
    }

    @include mq($dt-min) {
        &::after {
            pointer-events: none;
            content: "";
            position: fixed;
            opacity: 0;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            z-index: -1;
            background: linear-gradient(1deg, rgb(0 0 0 / 50%) 42.91%, rgb(0 0 0 / 0%) 76.23%);
            transition: opacity 0.3s;
        }

        &:has(.main-header-menu-item:hover) {
            &::after {
                opacity: 0.3;
            }
        }

        body:not(.mouse-navigation) &:has(.main-header-menu-item:focus-within) {
            &::after {
                opacity: 0.3;
            }
        }
    }

    .scroll-wrapper {
        @include mq($until-dt-min) {
            position: relative;
            display: flex;
            flex-direction: column;
            min-height: fit-content;
            height: fill-available;

            @include mq($hover) {
                height: 100%;
            }
        }

        @include mq($dt-min) {
            height: 100%;
            width: 100%;
        }
    }
}

.main-header-menu {
    display: flex;
    height: 100%;

    @include mq($until-dt-min) {
        flex-direction: column;
        padding: 24px 16px;
        height: auto;
        flex: 1;
        margin-top: -14px;
    }

    @include mq($dt-min) {
        gap: 16px;
    }

    a {
        font-family: $heading-pro-double-regular;
        font-size: 16px;
        font-style: normal;
        line-height: 150%;
        text-transform: none;
    }

    .main-header-menu-item {
        max-width: 100%;

        @include mq($dt-min) {
            &:hover,
            &:focus-within:not(.mouse-navigation &) {
                > a::after {
                    color: $safety-orange;
                    transform: scale(1, -1);
                }

                .main-header-sub-menu-wrapper {
                    display: block;
                }
            }

            &:hover,
            &.active,
            &:focus-within:not(.mouse-navigation &) {
                > a::before {
                    content: "";
                    position: absolute;
                    height: 4px;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    background-color: $safety-orange;
                }
            }

            .main-header-sub-menu-wrapper {
                position: fixed;
                width: 100%;
                left: 0;
                top: var(--header-height);
                pointer-events: none;
            }
        }

        @include mq($until-dt-min) {
            margin-top: 12px;

            &.active {
                > a::before {
                    content: "";
                    position: absolute;
                    width: 4px;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    background-color: $safety-orange;
                }
            }
        }

        > a {
            position: relative;
            padding: 8px;
            display: flex;
            align-items: center;
            height: 100%;
            min-height: 60px;

            &:has(+ .main-header-sub-menu-wrapper) {
                &::after {
                    @include iconfont-styles;

                    content: svg(arrow-menu);
                    display: block;
                    font-size: 16px;
                    position: relative;
                    transform: scale(1, 1);
                    transition: transform 0.3s ease;

                    @include mq($until-dt-min) {
                        transform: rotate(-90deg);
                        margin-left: auto;
                    }
                }

                &:hover {
                    &::after {
                        color: $safety-orange;
                    }
                }
            }

            @include mq($until-dt-min) {
                font-family: $heading-pro-treble-extra-bold;
                font-size: 15px;
                line-height: 130%;
                background-color: $white;
                padding: 16px;
                gap: 16px;
            }

            @include mq($dt-min) {
                gap: 8px;
            }

            .icon {
                display: none;
                position: relative;
                flex-shrink: 0;

                @include mq($until-dt-min) {
                    display: block;
                    width: 32px;
                    height: 26px;

                    &::before {
                        content: "";
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                    }
                }
            }
        }
    }

    .main-header-menu-item-visual {
        max-width: 100%;
        height: 180px;
        position: absolute;
        pointer-events: none;
        bottom: 0;
        right: 0;
        z-index: -1;

        @include mq($dt-min) {
            display: none;
        }

        img {
            max-width: 100%;
            height: 100%;
            display: block;
            position: relative;
        }
    }

    .main-header-sub-menu-wrapper {
        display: none;

        @include mq($until-dt-min) {
            flex-direction: column;
            display: flex;
            position: fixed;
            top: 56px;
            bottom: 0;
            width: 100%;
            max-width: 360px;
            background-color: $wild-sand;
            z-index: 2;
            opacity: 0;
            right: -360px;
            transition:
                opacity 0.3s ease-in-out,
                transform 0.3s ease-out;

            &.visible {
                opacity: 1;
                pointer-events: auto;
                transform: translate3d(-360px, 0, 0);
            }
        }

        @include mq($mobile-pt) {
            max-width: 100%;
            right: -100%;

            &.visible {
                transform: translate3d(-100%, 0, 0);
            }
        }
    }

    .main-header-sub-menu-container {
        overflow: auto;
        height: 100%;
        width: 100%;

        @include scrollbar;

        @include mq($until-dt-min) {
            background-color: $wild-sand;
        }

        @include mq($dt-min) {
            display: flex;
            max-width: 1200px;
            padding: 0 40px;
            margin: auto;

            &:has(.main-header-sub-menu-column:nth-of-type(1):nth-last-of-type(1)) {
                .main-header-sub-menu {
                    max-width: 400px;
                }

                .main-header-sub-menu-column {
                    flex-basis: 100%;
                }
            }

            &:has(.main-header-sub-menu-column:nth-of-type(2):nth-last-of-type(1)) {
                .main-header-sub-menu {
                    max-width: 752px;
                }

                .main-header-sub-menu-column {
                    flex-basis: 50%;
                }
            }

            &:has(.main-header-sub-menu-column:nth-of-type(3):nth-last-of-type(1)) {
                .main-header-sub-menu {
                    max-width: 1200px;
                }

                .main-header-sub-menu-column {
                    flex-basis: 33.33%;
                }
            }

            &:has(.main-header-sub-menu-column:nth-of-type(4):nth-last-of-type(1)) {
                .main-header-sub-menu {
                    max-width: 1200px;
                }

                .main-header-sub-menu-column {
                    flex-basis: 25%;
                }
            }
        }
    }

    .touch-menu-sub-header {
        display: none;

        @include mq($until-dt-min) {
            display: flex;
            padding: 24px 16px;
            background-color: $wild-sand;
            z-index: 1;
        }

        .touch-menu-back {
            font-family: $heading-pro-treble-extra-bold;
            background-color: $white;
            padding: 16px 24px;
            width: 100%;
            font-size: 15px;
            line-height: 130%;
            cursor: pointer;
            display: flex;
            gap: 16px;
            align-items: center;
            min-height: 60px;
            border: 0;

            &::before {
                @include iconfont-styles;

                content: svg(arrow-menu);
                display: block;
                font-size: 16px;
                position: relative;
                transform: rotate(90deg);
            }

            &:hover {
                &::before {
                    color: $safety-orange;
                }
            }
        }
    }

    .main-header-sub-menu {
        @include mq($until-dt-min) {
            margin-top: -18px;

            &:has(+ .main-header-sub-menu-visual) {
                padding-bottom: 146px;
            }
        }

        @include mq($dt-min) {
            display: flex;
            width: 100%;
            gap: 32px;
            padding: 40px;
            background-color: $white;
            pointer-events: all;
            position: relative;
            box-shadow: 0 2px 2px 0 rgb(0 0 0 / 15%);
        }
    }

    .main-header-sub-menu-column {
        @include mq($until-dt-min) {
            &:not(.main-header-sub-menu-visual) {
                padding: 32px 24px 16px;
                border-top: 2px solid $white;
                position: relative;
            }
        }

        @include mq($dt-min) {
            padding: 8px 16px 0;
        }
    }

    .main-header-sub-menu-column-title {
        font-family: $heading-pro-treble-extra-bold;
        font-size: 14px;
        line-height: 130%;
        text-transform: uppercase;
        display: block;

        @include mq($dt-min) {
            margin: 8px 0;
        }

        @include mq($until-dt-min) {
            padding: 0 0 8px;
        }
    }

    .main-header-sub-menu-list-item {
        padding: 16px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 64px;

        @include mq($dt-min) {
            min-height: 48px;
            padding: 0;
        }
    }

    .main-header-sub-menu-list {
        a {
            font-family: $heading-pro-double-regular;
            font-size: 16px;
            line-height: 150%;
            display: block;

            @include line-clamp;

            @include mq($dt-min) {
                min-height: 24px;
                margin: 8px 0;
            }

            @include mq($hover) {
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .main-header-sub-menu-visual {
        max-width: 100%;
        height: 180px;
        position: absolute;
        pointer-events: none;
        bottom: 0;
        right: 0;
        z-index: 0;

        @include mq($dt-min) {
            display: none;
        }

        img {
            max-width: 100%;
            height: 100%;
            display: block;
            position: relative;
        }
    }
}

.header-row-navigation .seamly-search-placeholder {
    order: 3;

    .seamly-search {
        @include mq($mobile-max) {
            .cx-search-box__button {
                right: 4px;
            }

            .cx-search-box__button--activated {
                right: 0;
            }
        }

        .cx-search-box__button {
            &::after {
                background-image: url("../../img/svg/navigation-search.svg");
            }
        }
    }

    .cx-search-box__popper--activated {
        width: calc(100vw - var(--scrollbar-width) - 64px);
        border: 0;
        max-width: none;
        height: 46px;
        top: -7px;

        @include mq($mobile-pt) {
            width: calc(100vw - var(--scrollbar-width) - 50px);
        }

        @include mq($not-mobile) {
            height: 56px;
            top: -12px;
        }

        @include mq($not-mobile-tablet-pt) {
            width: calc(100vw - var(--scrollbar-width) - 88px);
        }

        @include mq($dt-min) {
            width: 500px;
            height: 72px;
            top: -20px;
        }

        @include mq($desktop-xxl) {
            width: 770px;
        }

        .cx-search-box__input {
            padding-left: 60px;
            box-shadow: none;
            @include input-placeholder($nero, 16px);
        }

        .cx-search-box__button {
            left: 0;
            width: 64px;
            height: 100%;
            border-right: 1px solid $wild-sand;
            border-left: 1px solid $wild-sand;

            &::after {
                left: 50%;
                transform: translate(-50%, -50%);
                background-size: 20px;
            }
        }
    }
}

.btn {
    &.become-member {
        order: 2;
        padding: 8px 16px;
        height: 30px;
        max-width: 343px;
        font-size: 14px;

        @include mq($mobile-pt-l) {
            width: auto;
        }

        @include mq($dt-min) {
            order: 4;
            padding: 8px 24px;
            height: 36px;
            min-width: 96px;
            max-width: 343px;
            font-size: 16px;
        }
    }
}

.hamburger-toggle {
    order: 4;
    display: block;
    background-color: transparent;
    padding: 0;
    border: 0;

    &::after {
        @include iconfont-styles;

        content: svg(menu);
        display: block;
        font-size: 24px;
        position: relative;
    }

    @include mq($dt-min) {
        display: none;
    }
}
